import firebase from "firebase/app";
import "firebase/firestore";

export const state = {
  passes: [],
  requestStatus: null,
  profile: null,
};

// eslint-disable-next-line no-unused-vars
var passListener = null;

export const mutations = {
  SET_PROFILE(state, profile) {
    state.profile = profile;
  },
  SET_REQUEST_STATUS(state, status) {
    state.requestStatus = status;
  },
  SET_PASSES(state, passes) {
    state.passes = passes;
  },
  UPDATE_PASS_USE(state, passUse) {
    const passes = [...state.passes];
    console.log("passes", passes);
    for (let x = 0; x < passes.length; x++) {
      console.log("looking at a pass", passUse.id)
      if (passes[x].id == passUse.id) {
        console.log("found the pass")
        if (!passes[x].usedCount) passes[x].usedCount = passUse.passUse;
        else passes[x].usedCount = passes[x].usedCount + passUse.passUse;
      }
    }
    console.log("passing passes", passes, passUse);
    state.passes = passes;
  }
};

export const actions = {
  async claimPass({ commit }, request) {
    console.log("looking for pass");
    var db = firebase.firestore();
    var docRef = await db.collection("passRequests").add(request);
    passListener = docRef.onSnapshot(function (snapshot) {
      let update = snapshot.data();
      console.log("snap", snapshot);
      console.log("pass request updated", update);
      commit("SET_REQUEST_STATUS", update.status);
      if (update.status == "success") {
        localStorage.passRequest = snapshot.id;
        commit("SET_PASSES", update.passes);
        commit("SET_PROFILE", { firstName: update.firstName, lastName: update.lastName, email: update.email });
      }
    });
  },
  async checkSavedPass({ commit }) {
    if (localStorage.passRequest && localStorage.passRequest != null) {
      console.log("looking up saved pass", localStorage.passRequest);
      var db = firebase.firestore();
      var docRef = await db.collection("passRequests").doc(localStorage.passRequest).get();
      if (docRef.exists) console.log("Saved pass found");
      var passData = docRef.data();
      commit("SET_PROFILE", { firstName: passData.firstName, lastName: passData.lastName, email: passData.email });
      commit("SET_PASSES", passData.passes);
      passListener = docRef.ref.onSnapshot(function (snapshot) {
        let update = snapshot.data();
        console.log("snap", snapshot);
        console.log("pass request updated", update);
        commit("SET_REQUEST_STATUS", update.status);
        if (update.status == "success") {
          commit("SET_PASSES", update.passes);
          commit("SET_PROFILE", { firstName: update.firstName, lastName: update.lastName, email: update.email });
        }
      });
    } else {
      console.log("no saved pass request.");
    }
  },
  clearProfile({ commit }) {
    commit("SET_PROFILE", null);
    localStorage.removeItem("passRequest");
    passListener = null;
  },
  updatePassUse({commit}, passUse) {
    commit("UPDATE_PASS_USE", passUse);
  }
};

export const getters = {
  passes(state) {
    return state.passes;
  },
  profile(state) {
    return state.profile;
  },
  requestStatus(state) {
    return state.requestStatus;
  },
};
