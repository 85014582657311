import firebase from "firebase/app";
import "firebase/firestore";

export const state = {
  artist: null,
};

export const mutations = {
  SET_ARTIST(state, artist) {
    state.artist = artist;
  },
};

export const actions = {
  async fetchArtist({ commit }, artistId) {
    var db = firebase.firestore();
    var docRef = db.collection("performers").doc(artistId);
    var doc = await docRef.get();

    if (doc.exists) {
      console.log("Found the artist profile.");
      var artist = doc.data();
      commit("SET_ARTIST", artist);
    } else {
      console.log("Could not find artist " + artistId);
    }
  },
  async fetchArtistBySlug({ commit }, artistSlug) {
    var db = firebase.firestore();
    var docRef = db.collection("performers").where("urlslug", "==", artistSlug);
    var doc = await docRef.get();

    if (!doc.empty) {
      commit("SET_ARTIST", doc.docs[0].data());
    } else {
      console.log("Could not find artist " + artistSlug);
    }
  },
};

export const getters = {
  artist(state) {
    return state.artist;
  },
};
