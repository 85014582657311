var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "timeline" }, [
    _c("div", { staticClass: "is-hidden-mobile" }, [
      _vm._v(_vm._s(_vm.longDate) + " | " + _vm._s(_vm.price)),
    ]),
    _c("div", { staticClass: "is-hidden-tablet" }, [
      _vm._v(_vm._s(_vm.shortDate)),
      _vm.shortTime ? _c("div", [_vm._v(_vm._s(_vm.shortTime))]) : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }