var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "field" }, [
    _c(
      "div",
      {
        staticClass: "control has-icons-left",
        class: { "has-icons-right": _vm.isValid },
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.emailString,
              expression: "emailString",
            },
          ],
          staticClass: "input",
          attrs: {
            type: "email",
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
          },
          domProps: { value: _vm.emailString },
          on: {
            keyup: _vm.validate,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.emailString = $event.target.value
            },
          },
        }),
        _vm._m(0),
        _vm.isValid
          ? _c("span", { staticClass: "icon is-small is-right" }, [
              _c("i", { staticClass: "fas fa-check" }),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-left" }, [
      _c("i", { staticClass: "fas fa-envelope" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }