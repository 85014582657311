import firebase from "firebase/app";
//import "firebase/firestore";

export const state = {
  venue: null
};

export const mutations = {
  SET_VENUE(state, venue) {
    state.venue = venue;
  }
};

export const actions = {
  async fetchVenue({ commit }, venueId) {
    var db = firebase.firestore();
    var docRef = db.collection("venues").doc(venueId);
    var doc = await docRef.get();

    if (doc.exists) {
      commit("SET_VENUE", doc.data());
    } else {
      console.log("Could not find venue " + venueId);
    }
  },
  async setVenue({ commit }, venue) {
    var db = firebase.firestore();
    await db
      .collection("venues")
      .doc(venue.id)
      .set(venue)
      .catch(function(error) {
        console.error("Error updating venue: ", error);
      });

    //console.log("Venue Recieved", venue);
    commit("SET_VENUE", venue);
  }
};

export const getters = {
  venue(state) {
    return state.venue;
  }
};
