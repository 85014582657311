var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "cal-body" }, [
      !_vm.end ||
      (_vm.eventStartMonth == _vm.eventEndMonth &&
        _vm.eventStartDay == _vm.eventEndDay)
        ? _c("div", [
            _c("div", { staticClass: "month" }, [
              _vm._v(_vm._s(_vm.eventStartMonth)),
            ]),
            _c("div", { staticClass: "day" }, [
              _vm._v(_vm._s(_vm.eventStartDay)),
            ]),
          ])
        : _vm._e(),
      _vm.end && _vm.eventStartMonth != _vm.eventEndMonth
        ? _c("div", { staticClass: "datecolumns" }, [
            _c("div", { staticClass: "datecolumn" }, [
              _c("div", { staticClass: "month" }, [
                _vm._v(_vm._s(_vm.eventStartMonth)),
              ]),
              _c("div", { staticClass: "day" }, [
                _vm._v(_vm._s(_vm.eventStartDay)),
              ]),
            ]),
            _c("div", { staticClass: "day lowdash" }, [_vm._v("-")]),
            _c("div", { staticClass: "datecolumn" }, [
              _c("div", { staticClass: "month" }, [
                _vm._v(_vm._s(_vm.eventEndMonth)),
              ]),
              _c("div", { staticClass: "day" }, [
                _vm._v(_vm._s(_vm.eventEndDay)),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.end &&
      _vm.eventStartMonth == _vm.eventEndMonth &&
      _vm.eventStartDay != _vm.eventEndDay
        ? _c("div", [
            _c("div", { staticClass: "month" }, [
              _vm._v(_vm._s(_vm.eventStartMonth)),
            ]),
            _c("div", { staticClass: "datecolumns" }, [
              _c("div", { staticClass: "datecolumn" }, [
                _c("div", { staticClass: "day" }, [
                  _vm._v(_vm._s(_vm.eventStartDay)),
                ]),
              ]),
              _c("div", { staticClass: "day" }, [_vm._v("-")]),
              _c("div", { staticClass: "datecolumn" }, [
                _c("div", { staticClass: "day" }, [
                  _vm._v(_vm._s(_vm.eventEndDay)),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }