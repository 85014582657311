import firebase from "firebase/app";
import "firebase/firestore";

export const state = {
  festival: null,
  festivalProfile: null
};

export const mutations = {
  SET_FESTIVAL(state, festival) {
    state.festival = festival;
  },
  SET_FESTIVAL_PROFILE(state, profile) {
    state.festivalProfile = profile;
  },
};

export const actions = {
  async fetchFestival({ commit }, festivalId) {
    var db = firebase.firestore();
    var docRef = db.collectionGroup("seasons").where("id", "==", festivalId);
    var doc = await docRef.get();

    if (!doc.empty) {
      console.log("Found the festival profile.", doc.docs[0]);
      commit("SET_FESTIVAL", doc.docs[0].data());
    } else {
      console.log("Could not find festival " + festivalId);
    }
  },
  async fetchFestivalBySlug({ commit }, artistSlug) {
    var db = firebase.firestore();
    var docRef = db.collectionGroup("seasons").where("urlslug", "==", artistSlug);
    var doc = await docRef.get();

    if (!doc.empty) {
      commit("SET_FESTIVAL", doc.docs[0].data());
    } else {
      console.log("Could not find festival " + artistSlug);
    }
  },
  async fetchFestivalProfile({ commit }, profileId) {
    var db = firebase.firestore();
    var profile = await db.collection("festivals").doc(profileId).get();
    commit("SET_FESTIVAL_PROFILE", profile.data());
  }
};

export const getters = {
  festival(state) {
    return state.festival;
  },
  festivalProfile(state) {
    return state.festivalProfile;
  }
};
