var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "columns is-mobile event-preview" }, [
    _c("div", { staticClass: "column is-one-third" }, [
      _c("div", { staticClass: "eventimagerapper" }, [
        _c("div", { staticClass: "image-holder-mobile" }, [
          _c("img", {
            staticClass: "event-image",
            attrs: { src: _vm.event.image, alt: "event.title" },
          }),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "column" },
      [
        _c("EventDateline", {
          attrs: { event: _vm.event, selectedDate: _vm.selectedDate },
        }),
        _c("h3", [_vm._v(_vm._s(_vm.event.title))]),
        _vm.artist
          ? _c("p", { staticClass: "artist" }, [
              _vm._v(_vm._s(_vm.artist.name)),
            ])
          : _vm._e(),
        _vm.venue
          ? _c("p", { staticClass: "venue" }, [_vm._v(_vm._s(_vm.venue.name))])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }