<template>
  <div class="columns is-mobile event-preview">
    <div class="column is-one-third">
      <div class="eventimagerapper">
        <div class="image-holder-mobile">
          <img :src="event.image" alt="event.title" class="event-image" />
        </div>
      </div>
    </div>
    <div class="column">
      <EventDateline :event="event" :selectedDate="selectedDate" />
      <h3>{{ event.title }}</h3>
      <p class="artist" v-if="artist">{{ artist.name }}</p>
      <p class="venue" v-if="venue">{{ venue.name }}</p>
    </div>
  </div>
</template>

<script>
import EventDateline from "@/components/event-dateline";

export default {
  props: {
    event: Object,
    selectedDate: Object,
    venue: Object,
    artist: Object,
  },
  components: {
    EventDateline,
  },
};
</script>

<style>
@media screen and (max-width: 769px) {
  .image-holder-mobile {
    width: 222px;
    height: 100px;
    margin-left: -61px;
  }
  .eventimagerapper {
    width: 100px;
    overflow: hidden;
    float: left;
    margin-right: 20px;
    border-radius: 6px;
  }
}
</style>
