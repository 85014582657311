<template>
  <div>
    <img id="logo" src="/img/gobo-black-square.png" alt="Gobo Logo" />
    <h3>Sign Out</h3>
    <p>Are you sure you want to sign out?</p>
    <div class="buttons is-centered">
      <button class="button is-fullwidth" @click="cancel">Cancel</button>
      <button class="button is-fullwidth is-primary" @click="logout">Sign Out</button>
    </div>
  </div>
</template>

<script>
import store from "../store";

export default {
  name: "LogOut",
  data() {
    return {};
  },
  methods: {
    cancel() {
      this.$emit("canceled");
    },
    logout() {
      console.log("Logging out");
      store.dispatch("clearProfile");
      this.$emit("loggedout");
    },
  },
};
</script>
