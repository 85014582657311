import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/storage";
import * as VueGoogleMaps from "gmap-vue";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import VueMeta from "vue-meta";
require("@/assets/gobo-tix.scss");

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBxt7GTz4w74LxqVtx31Ert13X1EmG3hhk",
    libraries: "places", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'gmap-vue/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,
});

Vue.use(VueMeta);

Vue.config.productionTip = false;

var app = "";

const configOptions = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASURMENTID
};

firebase.initializeApp(configOptions);
Vue.prototype.$analytics = firebase.analytics();
firebase.storage();

Vue.prototype.$analytics = firebase.analytics();

firebase.auth().onAuthStateChanged((user) => {
  //store.dispatch("fetchUser", user);
  console.log(user);

  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});

Bugsnag.start({
  apiKey: "e6beb1c898c22f3b0159a0c51dd1b17f",
  plugins: [new BugsnagPluginVue()],
});
const bugsnagVue = Bugsnag.getPlugin("vue");
bugsnagVue.installVueErrorHandler(Vue);
