import firebase from "firebase/app";
import "firebase/firestore";

export const state = {
  lineItems: [],
  summary: {},
  discountError: "",
  taxSettings: { chargeSalesTax: false, salesTaxRate: 0 },
};

var cartRef = null;
var cartListener = null;

export const mutations = {
  ADD_LINE_ITEM(state, lineItem) {
    state.lineItems.push(lineItem);
  },
  ADD_DONATION(state, donation) {
    if (state.summary.donation) state.sumary.donation += donation;
    else state.summary.donation = donation;
    console.log("donation set", state.summary);
  },
  UPDATE_LINE_ITEM(state, lineItem) {
    let index = matchLineItem(lineItem);
    if (index > -1) state.lineItems[index] = lineItem;
  },
  SET_CART_SUMMARY(state, summary) {
    state.summary = summary;
  },
  SET_CART_TOTAL(state, total) {
    state.summary.total = total;
  },
  SET_CART_SUBTOTAL(state, subtotal) {
    state.summary.subtotal = subtotal;
  },
  SET_CART_TAX(state, tax) {
    state.summary.salesTax = tax;
  },
  CLEAR_CART(state) {
    state.summary = {};
    state.lineItems = [];
  },
  ORDER_COMPLETE(state) {
    state.summary.status == "complete";
  },
  REMOVE_LINE_ITEM(index) {
    let newarray = state.lineItems;
    newarray.splice(index, 1);
    state.lineItems = newarray;
  },
  INVALID_DISCOUNT_CODE(state) {
    state.discountError = "Invalid discount code.";
  },
  APPLY_PASS(state, pass) {
    let newpasses = {};
    if (state.summary.passes) newpasses = { ...state.summary.passes };
    if (!newpasses[pass.passId]) {
      newpasses[pass.passId] = pass;
      newpasses[pass.passId].uses = pass.uses;
      newpasses[pass.passId].discount = pass.discount;
    } else {
      newpasses[pass.passId].discount += pass.discount;
      newpasses[pass.passId].uses += pass.uses;
    }
    let summary = state.summary;
    summary.passes = newpasses;
    // let discount = 0;
    // for (let x = 0; x < newpasses.length; x++) discount += newpasses[x].discount;
    // summary.passDiscountTotal = discount;
    // summary.total = summary.subtotal - discount;
    // console.log("new summary", summary);
    state.summary = summary;
  },
  REMOVE_PASS_USE(state, removal) {
    const passes = state.summary.passes;
    let newsummary = state.summary;
    newsummary.passDiscountTotal -= removal.quantity * removal.price;
    console.log("passing in the night", state.summary);
    for (const pass of Object.keys(passes)) {
      console.log("xing", passes[pass], removal.quantity);
      if (passes[pass].uses == removal.quantity) {
        passes[pass].uses = 0;
        passes[pass].discount = 0;
        removal.quantity = 0;
      } else if (passes[pass].uses > removal.quantity) {
        passes[pass].uses -= removal.quantity;
        passes[pass].discount -= removal.quantity * removal.price;
        removal.quantity = 0;
      } else {
        removal.quantity -= passes[pass].uses;
        passes[pass].uses = 0;
        passes[pass].discount = 0;
      }
    }
    console.log("downhere", passes);
    newsummary.passes = passes;
    state.summary = newsummary;
  },
  SET_DISCOUNT(state, discount) {
    console.log("Discounting", discount);
    var limit =
      discount.limit & (discount.limit < state.lineItems.length + 1)
        ? discount.limit
        : state.lineItems.length + 1;
    if (discount.discountType == "comp") {
      state.lineItems.forEach((item) => {
        if (
          !discount.selectDates ||
          (discount.selectDates && discount.selectedDates.includes(item.dateId))
        ) {
          item.discountCode = discount.discountCode;
          if (discount.limit && discount.limit < item.quantity) {
            item.total = item.price * (item.quantity - limit);
          } else {
            item.price = 0;
            item.total = 0;
          }
        }
      });
    } else if (discount.discountType == "percent") {
      state.lineItems.forEach((item) => {
        if (
          !discount.selectDates ||
          (discount.selectDates && discount.selectedDates.includes(item.dateId))
        ) {
          var appliedqty =
            discount.limit && item.quantity > discount.limit
              ? discount.limit
              : item.quantity;
          item.originalPrice = item.price;
          item.price = item.price * discount.modifier;
          item.discount = item.originalPrice - item.price;
          item.total = item.total - discount.modifier * appliedqty;
          item.discountCode = discount.discountCode;
        }
      });
    } else if (discount.discountType == "ammount") {
      console.log("discout", discount);
      state.lineItems.forEach((item) => {
        if (
          !discount.selectDates ||
          (discount.selectDates && discount.selectedDates.includes(item.dateId))
        ) {
          var appliedqty =
            discount.limit && item.quantity > discount.limit
              ? discount.limit
              : item.quantity;
          item.originalPrice = item.price;
          item.price = item.price - discount.modifier;
          item.discount = item.originalPrice - item.price;
          item.total = item.total - discount.modifier * appliedqty;
          item.discountCode = discount.discountCode;
          console.log("iten", item);
        }
      });
    } else if (discount.discountType == "price") {
      console.log("discount", discount);
      state.lineItems.forEach((item) => {
        if (
          !discount.selectDates ||
          (discount.selectDates && discount.selectedDates.includes(item.dateId))
        ) {
          var appliedqty =
            discount.limit && item.quantity > discount.limit
              ? discount.limit
              : item.quantity;
          item.originalPrice = item.price;
          item.price = discount.fixedPrice;
          item.discount = item.originalPrice - item.price;
          item.total = item.price * appliedqty;
          item.discountCode = discount.discountCode;
          console.log("item", item);
        }
      });
    } else if (discount.discountType == "bogo") {
      state.lineItems.forEach((item) => {
        if (
          !discount.selectDates ||
          (discount.selectDates && discount.selectedDates.includes(item.dateId))
        ) {
          var appliedqty =
            discount.limit && item.quantity > discount.limit
              ? discount.limit
              : item.quantity;
          // freebies is a legitimate variable name. don't @ me.
          var freebies = Math.trunc(appliedqty / 2);
          item.originalPrice = item.price;
          item.discount = "bogo";
          item.total = item.total - freebies * item.price;
          item.discountCode = discount.discountCode;
        }
      });
    }
  },
  SET_TAX_RATE(state, rate) {
    // State meet Rate, Rate meet State
    state.taxSettings.chargeSalesTax = true;
    state.taxSettings.salesTaxRate = rate;
  }
};

export const actions = {
  async addToCart({ commit }, lineItem) {
    if (!state.summary.eventId && lineItem.eventId)
      commit("SET_CART_SUMMARY", {
        eventId: lineItem.eventId,
        ...state.summary,
      });
    if (!state.summary.festivalId && lineItem.festivalId)
      commit("SET_CART_SUMMARY", {
        festivalId: lineItem.festivalId,
        seasonId: lineItem.seasonId,
        ...state.summary,
      });
    var db = firebase.firestore();
    await cartCheck(commit);
    console.log("here we go", state.summary);
    var matched = matchLineItem(lineItem);
    if (matched > -1) {
      lineItem.quantity =
        parseInt(lineItem.quantity) +
        parseInt(state.lineItems[matched].quantity);
      lineItem.total = lineItem.price * lineItem.quantity;
      commit("UPDATE_LINE_ITEM", lineItem);
    } else {
      await db
        .collection("carts")
        .doc(localStorage.cart)
        .collection("lineItems")
        .add(lineItem)
        .catch(function (error) {
          console.error("Error adding line item: ", error);
        });
      commit("ADD_LINE_ITEM", lineItem);
    }
    updateCartTotal(commit);
  },
  addDonation({ commit }, donation) {
    console.log("adding donation", donation);
    commit("ADD_DONATION", donation);
  },
  applyPass({ commit }, pass) {
    console.log("Using Pass", pass);
    commit("APPLY_PASS", pass);
  },
  removeLineItem({ commit }, index) {
    if (state.lineItems[index].passUse) {
      commit("REMOVE_PASS_USE", { quantity: state.lineItems[index].quantity, price: state.lineItems[index].price });
    }
    commit("REMOVE_LINE_ITEM", index);
    updateCartTotal(commit);
  },
  clearCart({ commit }) {
    // localStorage.cart = null;
    localStorage.removeItem("cart");
    commit("CLEAR_CART");
  },
  async placeOrder({ commit }, header) {
    console.log("before check", header);
    await cartCheck(commit);
    console.log("before check", header);
    header.cartId = localStorage.cart;
    var db = firebase.firestore();
    var orderRef = await db
      .collection("orders")
      .add(header)
      .catch(function (error) {
        console.error("Error adding order header: ", error);
      });
    console.log(state.lineItems);
    for (let i = 0; i < state.lineItems.length; i++) {
      await db
        .collection("orders")
        .doc(orderRef.id)
        .collection("lineItems")
        .add(state.lineItems[i])
        .catch(function (error) {
          console.error("Error adding order line item: ", error);
        });
    }
    header.id = orderRef.id;
    cartRef = orderRef;
    cartListener = orderRef.onSnapshot(function (snapshot) {
      let update = snapshot.data();
      console.log("order updated", update);
      commit("SET_CART_SUMMARY", update);
    });
    orderRef.update({ status: "submitted" });
    commit("SET_CART_SUMMARY", header);
  },
  async paymentComplete({ commit }, result) {
    console.log("payment complete");
    cartRef.update({ status: "paymentComplete", paymentResult: result });
    //cartListener = null;
    //cartRef = null;
    commit("ORDER_COMPLETE");
  },
  async stopListener() {
    cartListener();
    cartListener = null;
    cartRef = null;
  },
  async getDiscount({ commit }, discountRequest) {
    var db = firebase.firestore();
    discountRequest.code = discountRequest.code.toUpperCase();
    var discounts = await db
      .collection("events")
      .doc(discountRequest.eventId)
      .collection("discounts")
      .where("discountCode", "==", discountRequest.code)
      .get();
    if (!discounts.empty) {
      commit("SET_DISCOUNT", discounts.docs[0].data());
      var newSummary = state.summary;
      newSummary.discountCode = discountRequest.code;
      //var oldtotal = newSummary.total;
      updateCartTotal(commit);
      let discount = 0;
      for (let x = 0; state.lineItems.length > x; x++) discount += ((state.lineItems[x].originalPrice * state.lineItems[x].quantity) - state.lineItems[x].total); 
      newSummary.discount = discount;
      commit("SET_CART_SUMMARY", newSummary);
    } else {
      commit("INVALID_DISCOUNT_CODE");
    }
  },
  setTaxRate({ commit }, taxRate) {
    console.log("setting tax rate");
    commit("SET_TAX_RATE", taxRate);
  },
};

export const getters = {
  summary(state) {
    return state.summary;
  },
  lineItems(state) {
    return state.lineItems;
  },
  discountError(state) {
    return state.discountError;
  },
};

async function cartCheck(commit) {
  console.log("cart check", localStorage.cart, state.summary);
  if (state.summary) {
    var db = firebase.firestore();
    if (
      localStorage.cart &&
      localStorage.cart != null &&
      localStorage.cart != "null"
    ) {
      console.log("existing cart", localStorage.cart);
      var cartRef = db.collection("carts").doc(localStorage.cart);
      var cart = await cartRef.get();

      if (cart.exists) {
        console.log("Found the freaking cart.", cart.data());
        // TODO FIX THIS
        // commit("SET_CART_SUMMARY", cart.data());
      } else {
        console.log("Could not find event " + localStorage.cart);
      }
      console.log("found the cart!", localStorage.cart);
    } else {
      console.log("creating cart");
      let cart = { created: Date.now() };
      if (state.summary.eventId) cart.eventId = state.summary.eventId;
      if (state.summary.festivalId) cart.festivalId = state.summary.festivalId;
      console.log("carting", state.summary);
      if (state.summary.passDiscountTotal) cart.passDiscountTotal = state.summary.passDiscountTotal;
      // if (state.summary.passes) cart.passDiscountTotal = state.summary.passes.discount;
      var docRef = await db
        .collection("carts")
        .add(cart)
        .catch(function (error) {
          console.error("Error creating cart: ", error);
        });

      console.log("Document written with ID: " + JSON.stringify(docRef.id));
      localStorage.cart = docRef.id;
      var objid = { id: docRef.id };
      var eventdoc = await docRef.get();
      var createdEvent = { ...objid, ...eventdoc.data() };
      createdEvent.id = docRef.id;
      // WHY ARE WE DOING THIS?
      commit("SET_CART_SUMMARY", createdEvent);
    }
  }
}

function updateCartTotal(commit) {
  var subtotal = 0;
  var total = 0;
  var salesTax = 0;
  // If we have a donation, add that in first
  if (state.summary.donation) subtotal = state.summary.donation;
  for (var i = 0; i < state.lineItems.length; i++) {
    if (state.lineItems[i].total) subtotal += state.lineItems[i].total;
  }
  commit("SET_CART_SUBTOTAL", subtotal);
  if (state.taxSettings.chargeSalesTax) {
    salesTax = Math.round(subtotal * state.taxSettings.salesTaxRate) / 100;
    commit("SET_CART_TAX", salesTax);
    total = subtotal + salesTax;
  } else total = subtotal;
  // Apply pass discount
  let discount = 0;
  // for (let x = 0; x < state.summary.passes.length; x++) discount += state.summary.passes[x].discount;
  if (state.summary.passes) {
      Object.keys(state.summary.passes).forEach(function(key) {
      discount += state.summary.passes[key].discount;
      console.log("key", key, discount)
    });
  }
  // console.log(state.summary, "discount", discount)
  total = total - discount;
  // console.log("new total", total)
  commit("SET_CART_TOTAL", total);
  console.log("Cart total: ", total);
}

function matchLineItem(lineItem) {
  var matched = -1;
  console.log("lip", state.lineItems.length);
  for (let i = 0; i < state.lineItems.length; i++) {
    console.log(state.lineItems[i], lineItem);
    if (
      state.lineItems[i].eventId == lineItem.eventId &&
      state.lineItems[i].dateId == lineItem.dateId &&
      state.lineItems[i].tierId == lineItem.tierId
    ) {
      matched = i;
    }
  }
  console.log("match this shit ", matched);
  return matched;
}
