<template>
  <div class="waitwrap">
    <div v-if="request && request.status == 'added'">
      <h1>You're on the list!</h1>
      <p>
        Congrats! We've added you to the waitlist for
        <strong>{{ event.title }}!</strong> You are <strong>#{{ request.position }}</strong> on
        the list. If tickets become available, you will be notified via email so
        that you can purchase them.
      </p>
    </div>
    <div v-if="!request || request.status != 'added'">
      <h1>Get on the Wait List</h1>
      <p>
        <strong>{{ event.title }}</strong> is sold out, but you can join the
        waitlist to get notified if and when tickets become available. Wait list
        participants will be notified on a first in, first out basis via email.
      </p>
      <div class="columns">
        <div class="column">
          <EmailField
            :email.sync="email"
            :valid.sync="valid"
            :disabled="loading"
          ></EmailField>
        </div>
        <div class="column is-narrow">
          <button
            class="button is-primary"
            :disabled="!valid"
            :class="{ 'is-loading': loading }"
            @click="signup"
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EmailField from "@/components/forms/email";
import firebase from "firebase/app";
import "firebase/firestore";

export default {
  name: "LogIn",
  data() {
    return {
      email: "",
      valid: false,
      loading: false,
      request: null,
      position: null,
    };
  },
  components: {
    EmailField,
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      event: "event",
    }),
  },
  methods: {
    async signup() {
      this.loading = true;
      var db = firebase.firestore();
      var waitRef = await db
        .collection("waitlist")
        .add({ email: this.email, eventId: this.event.id })
        .catch(function (error) {
          console.error("Error adding user to waitlist ", error);
        });
      waitRef.onSnapshot((snapshot) => {
        this.request = snapshot.data();
        this.position = snapshot.data().position;
        console.log("waitrequest updated", this.request, snapshot.data().position);
      });
    },
  },
};
</script>

<style>
.waitwrap {
  padding: 40px 0;
}
</style>
