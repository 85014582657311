var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "waitwrap" }, [
    _vm.request && _vm.request.status == "added"
      ? _c("div", [
          _c("h1", [_vm._v("You're on the list!")]),
          _c("p", [
            _vm._v(" Congrats! We've added you to the waitlist for "),
            _c("strong", [_vm._v(_vm._s(_vm.event.title) + "!")]),
            _vm._v(" You are "),
            _c("strong", [_vm._v("#" + _vm._s(_vm.request.position))]),
            _vm._v(
              " on the list. If tickets become available, you will be notified via email so that you can purchase them. "
            ),
          ]),
        ])
      : _vm._e(),
    !_vm.request || _vm.request.status != "added"
      ? _c("div", [
          _c("h1", [_vm._v("Get on the Wait List")]),
          _c("p", [
            _c("strong", [_vm._v(_vm._s(_vm.event.title))]),
            _vm._v(
              " is sold out, but you can join the waitlist to get notified if and when tickets become available. Wait list participants will be notified on a first in, first out basis via email. "
            ),
          ]),
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              { staticClass: "column" },
              [
                _c("EmailField", {
                  attrs: {
                    email: _vm.email,
                    valid: _vm.valid,
                    disabled: _vm.loading,
                  },
                  on: {
                    "update:email": function ($event) {
                      _vm.email = $event
                    },
                    "update:valid": function ($event) {
                      _vm.valid = $event
                    },
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "column is-narrow" }, [
              _c(
                "button",
                {
                  staticClass: "button is-primary",
                  class: { "is-loading": _vm.loading },
                  attrs: { disabled: !_vm.valid },
                  on: { click: _vm.signup },
                },
                [_vm._v(" Sign Up ")]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }