var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "body-wrapper" }, [
    _c("div", { staticClass: "body-content" }, [
      _c("div", { staticClass: "container" }, [
        _c("h1", [_vm._v("Upcoming Events")]),
        _c(
          "table",
          { attrs: { id: "eventTable" } },
          _vm._l(_vm.events, function (event) {
            return _c("tr", { key: event.id }, [
              _c(
                "td",
                { staticClass: "is-hidden-mobile" },
                [
                  event.start
                    ? _c("EventDateBadge", {
                        attrs: {
                          date: event.start.toDate(),
                          end: event.end.toDate(),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c("div", { staticClass: "eventimagerapper" }, [
                    _c("div", { staticClass: "imageholder" }, [
                      _c("img", {
                        staticClass: "eventimage",
                        attrs: { src: event.image, alt: "" },
                      }),
                    ]),
                  ]),
                  _c("EventDateline", { attrs: { event: event } }),
                  _c(
                    "div",
                    { staticClass: "eventTitle" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "event", params: { id: event.id } },
                          },
                        },
                        [_vm._v(_vm._s(event.title))]
                      ),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "artist" }, [
                    _vm._v(_vm._s(event.act)),
                  ]),
                  _c("div", { staticClass: "is-hidden-tablet" }, [
                    _vm._v(_vm._s(event.venueName)),
                  ]),
                ],
                1
              ),
              _c("td", { staticClass: "is-hidden-mobile" }, [
                _vm._v(_vm._s(event.venueName)),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }