<template>
  <div id="app">
    <nav
      v-if="this.$route.name != 'embed'"
      id="nav"
      class="navbar"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-brand">
        <a href="http://www.gobo.show"><img class="navlogo" src="/img/gobo-logo-white.svg" alt="" /></a>
        <a
          role="button"
          class="navbar-burger"
          :class="{ 'is-active': burgerToggle }"
          aria-label="menu"
          aria-expanded="false"
          @click="burgerToggle = !burgerToggle"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div class="navbar-menu" :class="{ 'is-active': burgerToggle }">
        <div id="center" class="navbar-item">
          <a class="navbar-item" href="https://www.gobo.show/about-us"
            >Buy Tickets</a
          >
          <a class="navbar-item" href="https://www.gobo.show/about-us"
            >About Gobo</a
          >
          <router-link class="navbar-item" to="/help">Help</router-link>
          <router-link v-if="profile" class="navbar-item" to="/pass"
            >View My Passes</router-link
          >
          <a v-if="profile" class="navbar-item" @click="logout = true">
            Sign Out
          </a>
        </div>
        <div class="navbar-end">
          <div class="navbar-item is-hidden-mobile" v-if="profile">
            <div class="navbar-item has-dropdown is-hoverable">
              <router-link class="navbar-link" to="/pass"
                >{{ profile.firstName }} {{ profile.lastName }}</router-link
              >
              <div class="navbar-dropdown is-boxed">
                <router-link class="navbar-item" to="/pass"
                  >View My Passes</router-link
                >
                <a class="navbar-item" @click="logout = true"> Sign Out </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <router-view @sellTickets="sellTickets" @sellPasses="sellPasses" @sellSeriesPasses="sellSeriesPasses" @addWaitlist="addWaitlist" />

    <div
      class="modal"
      :class="{
        'is-active': buyTickets || buyPasses || buySeriesPasses || logout || waitlist,
      }"
    >
      >
      <div class="modal-background is-hidden-mobile"></div>
      <div class="modal-content">
        <div id="modalwrapper" class="box">
          <div id="modalheader" v-if="waitlist || logout">
            <a id="modalclose" @click="canceled()" v-if="waitlist || !invitationId"
              ><i class="fas fa-times"></i
            ></a>
          </div>
          <BuyTickets
            v-if="buyTickets"
            :visible="buyTickets"
            @canceled="canceled"
          />
          <BuyPasses
            v-if="buyPasses"
            :visible="buyPasses"
            @canceled="canceled"
          />
          <BuySeriesPasses
            v-if="buySeriesPasses"
            :visible="buySeriesPasses"
            @canceled="canceled"
          />
          <div class="modal-inner-wrapper" v-if="logout || waitlist">
            <LogOut @canceled="canceled" @loggedout="loggedout" v-if="logout" />
            <Waitlist @canceled="canceled" v-if="waitlist" />
          </div>
        </div>
      </div>
    </div>
    <footer class="footer" v-if="this.$route.name != 'embed'">
      <nav
        id="footer-nav-top"
        class="navbar is-hidden-mobile"
        role="navigation"
        aria-label="secondary navigation"
      >
        <div class="navbar-brand">
          <img class="navlogo" src="/img/gobo-logo-white.svg" alt="Gobo" />
        </div>
      </nav>
      <div class="social is-hidden-tablet">
        <a href="https://twitter.com/goboshow/"
          ><span class="icon is-large">
            <i class="fab fa-2x fa-twitter"></i> </span
        ></a>
        <a href="https://www.facebook.com/Goboshow/"
          ><span class="icon is-large">
            <i class="fab fa-2x fa-facebook-f"></i> </span
        ></a>
        <a href="https://www.instagram.com/goboshow/"
          ><span class="icon is-large">
            <i class="fab fa-2x fa-instagram"></i> </span
        ></a>
      </div>
      <nav class="level is-mobile is-hidden-tablet">
        <div class="level-left">
          <img class="navlogo" src="/img/gobo-logo-white.svg" alt="Gobo" />
        </div>
        <div class="level-right"><div id="copy">&copy;{{ new Date().getFullYear() }} Gobo Show, Inc</div></div>
      </nav>
      <div class="content has-text-centered is-hidden-mobile">
        <div id="spacer"></div>
        <nav
          id="footer-nav"
          class="navbar"
          role="navigation"
          aria-label="secondary navigation"
        >
          <div class="navbar-brand">
            <div id="copy">&copy;{{ new Date().getFullYear() }} Gobo Show, Inc</div>
          </div>
          <div id="center" class="navbar-item is-hidden-mobile">
            <a class="navbar-item" href="https://www.gobo.show/about-us"
              >Our Story</a
            >
            <router-link class="navbar-item" to="/help">Help</router-link>
            <a class="navbar-item" href="https://www.gobo.show/privacy-notice"
              >Privacy Notice</a
            >
            <router-link class="navbar-item" to="/about" v-if="false"
              >Terms of Service</router-link
            >
            <div class="navbar-end">
              <div class="navbar-item">
                <a href="https://twitter.com/goboshow"
                  ><img
                    class="socialicon"
                    src="/img/icons/Twitter.svg"
                    alt="Follow us on Twitter"
                /></a>
              </div>
              <div class="navbar-item">
                <a href="https://www.facebook.com/Goboshow/">
                  <img
                    class="socialicon"
                    src="/img/icons/Facebook.svg"
                    alt="Follow us on Facebook"
                /></a>
              </div>
              <div class="navbar-item">
                <a href="https://www.instagram.com/Goboshow/">
                  <img
                    class="socialicon"
                    src="/img/icons/Instagram.svg"
                    alt="Follow us on Instagram"
                /></a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </footer>
  </div>
</template>

<style lang="scss">
.navlogo {
  margin-left: 1em;
  margin-top: 0.25em;
  height: 40px;
}
.socialicon {
  height: 20px;
}
#hero.hero {
  background-image: url("/img/hero.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 700px;
}
#nav {
  background: none;
  padding: 1.5em 2em 0;
}
#center {
  flex: 1;
  justify-content: center;
  padding-left: 130px;
}
#copy {
  font-size: 0.75em;
  padding: 1rem 0.75rem;
}
#spacer {
  min-height: 16rem;
}
.modal-content {
  margin: 0;
}
.modal-inner-wrapper {
  margin: 50px 200px;
}
</style>

<script>
import { mapGetters } from "vuex";
import BuyTickets from "@/components/buy-tickets";
import BuyPasses from "@/components/buy-passes";
import BuySeriesPasses from "@/components/buy-series-passes";
import LogOut from "@/components/logout";
import Waitlist from "@/components/waitlist";

export default {
  name: "gobo-tix",
  metaInfo() {
    return {
      title: "Gobo Tickets",
      meta: [
        { vmid: "description", name: "description", content: "Gobo is a live event ticket sales platform designed to make selling tickets easy, transparent, and affordable. Buy tickets to our events here." },
        ]
    };
  },
  components: {
    BuyTickets,
    BuyPasses,
    BuySeriesPasses,
    LogOut,
    Waitlist,
  },
  data() {
    return {
      burgerToggle: false,
      buyTickets: false,
      buyPasses: false,
      buySeriesPasses: false,
      locked: false,
      logout: false,
      waitlist: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile",
    }),
  },
  methods: {
    sellTickets() {
      this.buyTickets = true;
    },
    sellPasses() {
      this.buyPasses = true;
    },
    sellSeriesPasses() {
      this.buySeriesPasses = true;
    },
    canceled() {
      this.buyTickets = false;
      this.buyPasses = false;
      this.buySeriesPasses = false;
      this.waitlist = false;
    },
    loggedout() {
      this.logout = false;
      this.$router.push("/");
    },
    addWaitlist() {
      this.waitlist = true;
    }
  },
  async created() {
    await this.$store.dispatch("checkSavedPass");
  },
};
</script>
