<template>
  <div class="timeline">
    <div class="is-hidden-mobile">{{ longDate }} | {{ price }}</div>
    <div class="is-hidden-tablet">{{ shortDate }}<div v-if="shortTime">{{ shortTime }}</div></div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    event: Object,
    selectedDate: Object,
  },
  methods: {
    formatTime(time) {
      if (/.*[AaPp][Mm]/.test(time)) return time;
      else return moment(time, "HH:mm").format("h:mm A");
    },
  },
  computed: {
    longDate() {
      if (this.selectedDate)
        return (
          moment(this.selectedDate.startDate).format("dddd, MMMM Do YYYY") +
          " | " +
          this.formatTime(this.selectedDate.startTime) +
          " - " +
          this.formatTime(this.selectedDate.endTime)
        );
      if (!this.event.start || !this.event.end) return "";
      if (
        moment(this.event.start.toDate()).isSame(
          moment(this.event.end.toDate()),
          "date"
        )
      )
        return (
          moment(this.event.start.toDate()).format("dddd, MMMM Do YYYY") +
          " | " +
          moment(this.event.start.toDate()).format("h:mma") +
          " - " +
          moment(this.event.end.toDate()).format("h:mma")
        );
      else
        return (
          moment(this.event.start.toDate()).format("MMMM Do") +
          " - " +
          moment(this.event.end.toDate()).format("MMMM Do YYYY")
        );
    },
    shortDate() {
      if (this.selectedDate)
        return moment(this.selectedDate.startDate).format("ddd, MMM Do YYYY");
      if (!this.event.start || !this.event.end) return "";
      if (
        moment(this.event.start.toDate()).isSame(
          moment(this.event.end.toDate()),
          "date"
        )
      ) return moment(this.event.start.toDate()).format("ddd, MMM Do YYYY");
      else
        return (
          moment(this.event.start.toDate()).format("MMM Do") +
          " - " +
          moment(this.event.end.toDate()).format("MMM Do YYYY")
        );
    },
    shortTime() {
      if (this.selectedDate)
        return (
          this.selectedDate.startTime +
          " - " +
          this.selectedDate.endTime
        );
      if (!this.event.start || !this.event.end) return "";
      if (
        moment(this.event.start.toDate()).isSame(
          moment(this.event.end.toDate()),
          "date"
        )
      )
        return (
          moment(this.event.start.toDate()).format("h:mma") +
          " - " +
          moment(this.event.end.toDate()).format("h:mma")
        );
      else
        return "";
    },
    price() {
      if (this.event.minPrice == this.event.maxPrice) {
        if (this.event.minPrice == 0) return "FREE";
        else return "$" + this.event.minPrice;
      } else
        return (
          "$" +
          (this.event.minPrice == 0 ? "FREE" : this.event.minPrice) +
          "-" +
          this.event.maxPrice
        );
    },
  },
};
</script>

<style></style>
