<template>
  <div class="body-wrapper">
    <div class="body-content">
      <div class="container">
        <h1>Upcoming Events</h1>
        <table id="eventTable">
          <tr v-for="event in events" :key="event.id">
            <td class="is-hidden-mobile">
              <EventDateBadge :date="event.start.toDate()" :end="event.end.toDate()" v-if="event.start" />
            </td>
            <td>
              <div class="eventimagerapper">
                <div class="imageholder">
                  <img :src="event.image" alt="" class="eventimage" />
                </div>
              </div>
              <EventDateline :event="event" />
              <div class="eventTitle">
                <router-link
                  :to="{ name: 'event', params: { id: event.id } }"
                  >{{ event.title }}</router-link
                >
              </div>
              <p class="artist">{{ event.act }}</p>
              <div class="is-hidden-tablet">{{ event.venueName }}</div>
            </td>
            <td class="is-hidden-mobile">{{ event.venueName }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import store from "@/store";
import EventDateBadge from "@/components/event-date-badge";
import EventDateline from "@/components/event-dateline";

export default {
  name: "events",
  components: {
    EventDateBadge,
    EventDateline,
  },
  computed: {
    ...mapGetters({
      events: "events",
    }),
  },
  methods: {},
  created() {
    console.log("Heyo");
    this.$store.dispatch("fetchEvents", {
      perPage: this.perPage,
      page: this.page,
    });
  },
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";
#eventTable .cal-body {
  border: none;
}
.event {
  clear: both;
  margin-bottom: 10px;
}
p.artist {
  font-size: 16px;
  font-family: $family-primary;
  margin: 0;
}
</style>
